import { useEffect } from "react";

export function Zendesk({ zendeskKey, auth }) {

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    if (auth) {
      document.body.appendChild(script);
      window.zESettings = {webWidget: { position: { horizontal: 'left', vertical: 'bottom' }}};
    } else {
      var externalResource = document.getElementById("ze-snippet");
      externalResource?.parentNode?.removeChild(externalResource);
    }
    return () => {
      let element = document.getElementById('launcher')
      element.style.display = 'none'
       }
  }, [auth])

    return null;
}


export default Zendesk;

